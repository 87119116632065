import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"

import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import DynaForm from "../../Forms/DynaForm";
import moment from "moment";


import {apiV2 } from '../../../api';


function MeetingEditor({meeting, onMeetingChanged}) {

  //const [meeting, setmeetingS] = useState(meeting);



  const saveMeeting = (values) => {   
    const vs = {...values};
    console.log(vs)
    if (vs && vs.effort) {
      vs.effort = vs.effort.map(x=> {
        const allDay = true; 
        const i = x.indexOf("-");
        if (i>-1) {
          return {desc: x.substring(0,6), allDay: allDay, start: moment(x.substring(0,i).trim()).toDate(), end: moment(x.substring(i+1)).toDate()}
        }
      });
    }

    apiV2(`meeting/revise/${meeting._id}`, 'POST', {values: vs}).then(res0 => {
      if (res0.error) {console.log(res0.error)} //setMessage({variant: "error", msg: res0.error});
      else {
        //setMessage({variant: "info", msg: "Esca event Saved"});
        console.log(res0)
        onMeetingChanged(res0.model);
      }
    });
  };

  if (meeting)
  return (
    <Card key={meeting._id} style={{ maxHeight: '458px', minHeight: '458px', overflow: 'auto' }}>
        <CardContent >
            <DynaForm
              onSubmit={(values) => saveMeeting(values)}
              submitLabel="Save"
              fields={[
                {
                  name: 'topic',
                  defaultValue: meeting.topic,
                  fieldType: 'text',
                  valueType: 'text',
                  placeholder: "Topic",
                  label: "Topic",
                  size: 12,
                  required:true
                },
                {
                  name: 'tags',
                  isEditing: true,
                  defaultValue: meeting.tags ,
                  fieldType: 'tags',
                  valueType: 'tags',
                  placeholder: "Tags",
                  label: "Tags",
                  size: 12,
                  required:false
                },
                {
                  name: 'shouldPublish',
                  defaultValue: meeting.shouldPublish,
                  fieldType: 'checkbox',
                  valueType: 'boolean',
                  placeholder: "",
                  label: "Publish?",
                  size: 3,
                  required:false
                },
                {
                  name: 'priceStandard',
                  defaultValue: meeting.priceStandard ,
                  fieldType: 'price',
                  valueType: 'price',
                  placeholder: "Standard Price",
                  label: "Standard Price",
                  size: 3,
                  required:true
                },
                {
                  name: 'priceMembers',
                  defaultValue: meeting.priceMembers ,
                  fieldType: 'price',
                  valueType: 'price',
                  placeholder: "Members Price",
                  label: "Members Price",
                  size: 3,
                  required:true
                },
                {
                  name: 'title',
                  defaultValue: meeting.title ,
                  fieldType: 'text',
                  valueType: 'text',
                  placeholder: "Product Title",
                  label: "Product Title",
                  size: 12,
                  required:true
                },
                {
                  name: 'description',
                  defaultValue: meeting.description,
                  fieldType: 'rtf',
                  valueType: 'json',
                  placeholder: "Short Description",
                  label: "Short Description",
                  size: 12,
                  required:false
                }                
              ]}
            />    
        </CardContent>
    </Card>          
  );
}

export default MeetingEditor;
/*
 topic: String,
        allowSee: [String],
        allowDo: [String],
        shouldRemove: Boolean,

*/