import {preload as preloadCommand, create as createCommand, togglePlacingTile } from "./MapCommand"
import {preload as preloadBattle, create as createBattle } from "./MapBattle"
import {preload as preloadCache, create as createCache } from "./MapCache"

import blankImage from "../../../images/blank_tile.jpeg"
import emptyImage from "../../../images/blank_scrabble_tile.png"

import mirrorImage from "../../../images/contemplative-reptile.jpg"
import cannonImage from "../../../images/cannon.png"
import transflectorImage from "../../../images/transflector.png"
import transzorberImage from "../../../images/transzorber.png"
import zorbflectorImage from "../../../images/zorbflector.png"
import flectorImage from "../../../images/flector.png"
import benderImage from "../../../images/bender.png"
import zorberImage from "../../../images/zorber.png"

let F;

// Define Phaser's preload function
export const preload = (scene, f) => {
  F = f;
  
  // Load images 
  scene.load.image('blank', blankImage);
  scene.load.image('empty', emptyImage);

  scene.load.image('mirror', mirrorImage);
  scene.load.image('cannon', cannonImage);
  scene.load.image('transflector', transflectorImage);
  scene.load.image('transzorber', transzorberImage);
  scene.load.image('zorbflector', zorbflectorImage);
  scene.load.image('flector', flectorImage);
  scene.load.image('bender', benderImage);
  scene.load.image('zorber', zorberImage);

  preloadBattle(scene, f);
  preloadCommand(scene, f);
  preloadCache(scene, f);

};

// Define Phaser's create function
export const create = (scene, osc, oc) => {

  createBattle(scene, osc, 0, 0);
  createCommand(scene, oc, 1, F.mapYs);
  createCache(scene, osc, 0, F.mapYs + F.commandYs);
};




