// AlertContext.js
import React, { createContext, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const AlertContext = createContext();
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);

    const notify = (severity, message) => {
        setAlert({ severity, message });
    };

    const closeAlert = () => {
        setAlert(null);
    };

    return (
        <AlertContext.Provider value={{ alert, notify, closeAlert }}>
            {children}
            {alert && <CustomAlertBox alert={alert} onClose={closeAlert} />}
        </AlertContext.Provider>
    );
};

function CustomAlertBox({ alert, onClose }) {
const styles = {
    customAlertBox: {
        /* common styles */
    },
    customAlertBoxError: {
        backgroundColor: 'red',
        color: 'white'
    },
    
    customAlertBoxWarning: {
        backgroundColor: 'yellow',
        color: 'black'
    },
    
    customAlertBoxInfo: {
        backgroundColor: 'blue',
        color: 'white'
    }
}

    // Customize the alert box based on severity
    const alertClass = `customAlertBox${alert.severity}`;
    console.log('Rendering alert:', alert);
/*
    return (
        <div style={styles[alertClass]}>
            <p>{alert.message}</p>
            <button onClick={onClose}>Close</button>
        </div>
    );
*/    
    return (
        <Snackbar open={alert.message !== ''} autoHideDuration={6000} onClose={onClose}>
        <Alert onClose={onClose} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    );
}
