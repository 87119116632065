import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"

import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import {getHostName, makeUrl } from '../../../api';


function BlankFileViewer() {
  return (
    <Card >
      <CardActionArea >
        <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
          No File Selected
          <Typography variant="body2" color="text.secondary">{`Click on a file to your left to view file ...`}</Typography>
        </Typography>
        <CardContent >
          <pre style={{ textAlign: 'center', maxHeight: '284px', minHeight: '284px', overflow: 'auto', fontSize: '10px' }}>
          </pre>        
        </CardContent>
      </CardActionArea>
    </Card>          
  );
}

export default BlankFileViewer;
