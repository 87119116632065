import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Edit from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import OutlinedDiv from "../OutlinedDiv"
import TagCard from '../Cards/TagCard';
import { useTheme } from "@mui/material/styles"
import Tiptap from './TipTap/TipTap';
import TiptapRO from './TipTap/TipTapRO';


/*
import { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FileCard from "../Cards/FileCard";
import ImageEditCard from "../Cards/ImageEditCard";
import ImageCard from "../Cards/ImageCard";
import TagCard from "../Cards/TagCard";
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import {
  RTEditor,
  Notification
} from 'dan-components';

import 'dan-styles/vendors/react-draft-wysiwyg/react-draft-wysiwyg.css';
import styles from './user-jss';
*/






const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item.defaultValue,
    };
  }, initialValue);
};

const convertArrayToNullObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: "",
    };
  }, initialValue);
};

// validation functions
const required = value => (value === null ? 'Required' : undefined);
const isValidEmail = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email'
    : undefined
);

function DynaForm(props) {
  const { classes, onSubmit, onChange, submitLabel, fields } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [editorOpen, seteditorOpen] = useState(false);
  const [editingField, seteditingField] = useState(null);
  const [jsonError, setJsonError] = useState( false);
  
  const theme = useTheme();
  const styles = {
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(1)
    },
    root: {
      margin: 0
    }
  }



  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [S, setS] = useState(convertArrayToObject(fields, 'name' ));
  const [V, setV] = useState(convertArrayToNullObject(fields, 'name' ));

  useEffect(() => { 
    setS(convertArrayToObject(fields, 'name' ));
    setV(convertArrayToNullObject(fields, 'name' ));  
   }, [fields]);


  const handleTextFieldChange = field => event => {
    setS({ ...S, [field.name]: event.target.value });
    switch(field.valueType) {
      case "email": setV( { ...V, [field.name]: isValidEmail(event.target.value) ?  "invalid" : "" }) ; break;
      case "password": 
        if (field.matchingField) setV( { ...V, [field.name]: event.target.value !== S[field.matchingField] ? "Does not match" : "", [field.matchingField]: event.target.value !== S[field.matchingField] ? "Does not match" : ""}); break;
      case "confirmPassword": setV( { ...V, [field.name]: event.target.value !== S[field.matchingField] ? "Does not match" : "", [field.matchingField]: event.target.value !== S[field.matchingField] ? "Does not match" : ""}); break;
      default: break;
    }

    if (field.onChange) field.onChange(event.target.value);
  };

  const handleEditorChange = (value) => {
    console.log(value)
    setS({ ...S, [editingField.name]: JSON.stringify(value) });
    seteditingField(null);

  };

  const handleTagsChange = field => value => {
    switch(field.valueType) {
      case "when": 
        const newValue = [];
        for(const v of value) {
          const allday = v.indexOf("ALL DAY") > -1;
          const weekly = v.indexOf("WEEKLY") > -1;
          const duration = weekly ? Number(v.substr(7,2)): 1;
          //const newV = v.replaceAll(" ", "");
          
          const newV = v.replace(/ -/g, '-').replace(/- /g, '-');
          var iS = newV.indexOf("-");
          var iE = newV.indexOf("-", iS + 1);
          if (!allday && !weekly) { iE = iS+1; iS = 0; }
          else {iE++; iS++}
          console.log("Dates", iS, iE)
          var startS = newV.substring(iS, iE-1).trim();

          if (startS.indexOf(":") === -1) {
            const l = startS.length;
            startS = startS.substring(0,l-2)+ ":" + startS.substr(l-2); 
            console.log(startS)
          }
          var startM = moment(startS);

          var endS = newV.substring(iE).trim();

          console.log("start stop", startM, endS)
          if (endS.length <= 5) {
            endS = `${startM.format("MM/DD/YYYY")} ${endS}`;
          } 
          if (endS.indexOf(":") === -1) {
            const l = endS.length;
            endS = endS.substring(0,l-2)+ ":" + endS.substr(l-2); 
          }
          var endM = moment(endS);
          if (endM.dayOfYear === startM.dayOfYear && weekly) endM.add(duration-1, 'weeks'); // assumes nothing ends on same day of future year as it starts

          while (startM.isSameOrBefore(endM)) {              
            newValue.push(`${allday ? 'ALL DAY' : ''} ${startM.format('MM/DD/YYYY HH:mm')} - ${startM.format('MM/DD/YYYY')} ${endM.format('HH:mm')}`); 
            if (weekly) startM.add(1, 'weeks');
            else startM.add(1,'days');
          }
        }
        setS({ ...S, [field.name]: newValue }); 
      break;
      default: setS({ ...S, [field.name]: value }); break;
    }
      if (field.onChange) field.onChange(value);
  };


  const handleDateTimeChange = field => date => {
    setS({ ...S, [field.name]: date });
  };

  const handleCheckChange = name => event => {
    S[name] = event.target.checked;
    setS({ ...S });

  };

  const onJsonChanged = field => event => {
    setJsonError(event.error)
    if (!event.error) {
      setS({...S, [field.name]: event.json});
    }

  };


  const isValid = () =>{
    for(const v in V) {
      if (V[v]) return false;
    }
    return true;
  }

  const openEditor = (field) => {
    seteditingField(field);
    seteditorOpen(true);

  };


  const handleChange = (event, name) => {
/*    
    const { value } = event.target;
    if (name === 'to') {
      setValidMail(email(event.target.value));
    }
    setField(prevState => ({
      ...prevState,
      [name]: value,
    }));
*/
  };

  const setScancodeLocal = (result) => {
    if (result) {
      alert(result.text);
    }
 }

const preProcessJson = (value) => {
  if (value) {
    const v = JSON.parse(value);
    console.log(v)
    //return v.content[0].content[0].text
    return value;
  //
  }

};

if (editingField) return <Tiptap content={S[editingField.name]} onCancel={()=>seteditingField(null)} onSave={(content) => handleEditorChange(content)}
/>
else return (
    <Grid container alignItems="center" justify="flex-start" direction="row" spacing={1} >     
    {fields.map((field, fieldIndex) => {

          return (
            <Grid key={`grid_${fieldIndex}`} item md={field.size} xs={Math.min(12, Math.ceil(field.size * 2.5))}>
            <div>
            {field.fieldType === 'space' &&
              <div></div>
            }
            
            {field.fieldType === 'title' &&
              <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                {S[field.name]}<br/>
                </h5>
              </div>            
            }
            
            {field.fieldType === 'notice' &&
              <div className="modal-header">
                <small>{S[field.name]}<br/></small>
              </div>            
            }
            
            {field.fieldType === 'error' &&
              <div className="modal-header">
                <small className={styles.redBtn}>{S[field.name]}<br/></small>
              </div>            
            }

            {field.fieldType === 'text' &&
              <FormControl sx={styles.formControl}>
                <TextField 
                  name={field.name}
                  readOnly={field.readOnly}
                  onChange={handleTextFieldChange(field)}
                  value={S[field.name] ? S[field.name] : ""}
                  helperText={V[field.name]}
                  placeholder={field.placeholder}
                  label={field.label}
                  required={field.required}
                  sx={styles.field}
                  multiline={field.lines ? true : false}
                  maxRows={field.lines ? field.lines : 0}
                />
              </FormControl>
            }
            
            {field.fieldType === 'secret' &&
              <FormControl className={styles.formControl}>
              <TextField
                  name={field.name}
                  readOnly={field.readOnly}
                  onChange={handleTextFieldChange(field)}
                  value={S[field.name] ? S[field.name] : ""}
                  helperText={V[field.name]}
                  label={field.label}
                  type={showPassword ? 'text' : 'password'}
                  required={field.required}
                  className={styles.field}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />                
            </FormControl>
            }

            {field.fieldType === 'number' &&
            <FormControl className={styles.formControl}>
              <TextField
                name={field.name}
                readOnly={field.readOnly}
                onChange={handleTextFieldChange(field)}
                value={S[field.name] ? S[field.name] : 0}
                helperText={V[field.name]}
                label={field.label}
                type="number"
                required={field.required}
                className={styles.quantity}

                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />  
            </FormControl>

            }

            
            {field.fieldType === 'checkbox' &&
              <FormControlLabel control={( <Checkbox disabled={field.readOnly} onChange={handleCheckChange(field.name)} checked={S[field.name] ? S[field.name] : false} /> )} label={field.label} />          
            }

            {field.fieldType === 'price' &&
            <FormControl className={styles.formControl}>
              <TextField
                name={field.name}
                readOnly={field.readOnly}
                onChange={handleTextFieldChange(field)}
                value={S[field.name] ? S[field.name] : 0}
                helperText={V[field.name]}
                label={field.label}
                type="number"
                required={field.required}
                className={styles.quantity}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                startadornment={<InputAdornment position="start">$</InputAdornment>}
              />  
            </FormControl>
            }      

            {/*field.fieldType === 'datetime' && 
              <FormControl className={styles.formControl}>
                <div className={styles.picker}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    views={["year"]}
                    name={field.name}
                    onChange={handleDateTimeChange(field)}
                    value={S[field.name]}
                    label={field.label}
                    required={field.required}
                    readOnly={field.readOnly}
                    style={{width: "100%"}}
                    format="LLL"
                  />
                </MuiPickersUtilsProvider>
              </div>      
            </FormControl>
          */}

            {field.fieldType === 'select' && 
              <FormControl sx={styles.formControl}>
                <InputLabel htmlFor={field.name+"Id"}>{field.label}</InputLabel>
                <Select
                  value={S[field.name] || ''}
                  readOnly={field.readOnly}
                  onChange={handleTextFieldChange(field)}
                  inputProps={{
                    name: field.name,
                    id: field.name+"Id",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  
                  {field.options && field.options.map((option, optionIndex) => {
                    return (
                      <MenuItem key={`${field.name}_mi_${optionIndex}`} value={option.id}>{option.label}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            }

            {field.fieldType === 'link' && 
              <div className={styles.optArea}>
                <Button size="small" component={LinkBtn} to={field.url} className={styles.buttonLink}>{field.label}</Button>
              </div>
            } 

            {field.fieldType === 'tags' && 
              <TagCard
                isEditing={field.isEditing}
                tagArray={S[field.name]}
                isAdmin={true}
                onChange={handleTagsChange(field)}
                onClick={field.onClick}
                label={field.label}
                placeholder={field.placeholder}
                isUpper={field.isUpper}
              />
          }
         
         {field.fieldType === 'rtf' &&  field.valueType === 'rtf' &&
              <OutlinedDiv label={field.label}>
                <Typography variant="body2">
                <IconButton color="secondary" onClick={() => openEditor(field)} ><Edit /></IconButton>
                {S[field.name] && S[field.name].replace(/(<([^>]+)>)/ig,"").substr(0,144 * (field.lines ? field.lines : 1))}
                </Typography>
              </OutlinedDiv>            
            }
                     
            {field.fieldType === 'rtf' && field.valueType === 'json' && <>
              <OutlinedDiv label={field.label}>
                <IconButton sx={{marginTop: "-38px", marginLeft: "104px"}} color="secondary" onClick={() => openEditor(field)} ><Edit /></IconButton>
                <TiptapRO content={S[field.name]} />
                       
              </OutlinedDiv>            
</>              }
            
            {/*field.fieldType === 'img' &&
              <OutlinedDiv label={field.label}>
                <ImageCard 
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  maxSize={5000000}
                  filesLimit={1}
                  text="Drop or click"
                  showPreviews={true}
                  suffix={field.suffix ? field.suffix : "image"}
                  cover={S[field.name]}
                  reload={false}
                  onDelete={() => { setS({ ...S, [field.name]: null })} }
                  selectionLink={field.selectionLink}
                />
              </OutlinedDiv>            
          */} 

            {/*field.fieldType === 'imgEdit' &&
                <ImageEditCard 
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  maxSize={5000000}
                  filesLimit={1}
                  text="Drop or click"
                  suffix={field.suffix}
                  cover={S[field.name]}
                  reload={false}
                  fieldName={field.name}
                  folder={field.folder}
                  iid = {field.iid}
                  onDelete={() => setS({...S, [field.name]: ""})}
                  beforeEdit={() => { onSubmit(S); }}
                  onChange={(url) => setS({...S, [field.name]: url})}    
                  canEdit={field.canEdit} 
                />
        */}       

            {/*field.fieldType === 'upload' &&
                <FileCard 
                  maxSize={500000000}
                  filesLimit={1}
                  text="Drop or click"
                  suffix={field.suffix}
                  cover={S[field.name]}
                  reload={false}
                  fieldName={field.name}
                  folder={field.folder}
                  iid = {field.iid}
                  //onDelete={() => setS({...S, [field.name]: ""})}
                  //beforeEdit={() => { onSubmit(S); }}
                  //onChange={(url) => setS({...S, [field.name]: url})}    
                  //canEdit 
                />
      */}                                     
            
            {/*field.fieldType === 'json' &&
              <OutlinedDiv label={field.label}>
                <JSONInput
                  id          = 'a_unique_id'
                  locale      = { locale }
                  height      = '550px'
                  width       = '100%'
                  placeholder = { S[field.name] ? JSON.parse(S[field.name]) : {}}
                  onChange = {onJsonChanged(field)}
                />        
              </OutlinedDiv>            
          
    */}

            {field.fieldType === 'command' &&
              <div className={styles.btnArea}>
                <Button variant="contained" fullWidth color="primary" size="large" type="button" onClick={() => field.onClick(S)}>
                  {field.label}
                </Button>      
                </div>
          
            }
{/*
            {field.fieldType === 'scanner' &&
                <>
                <BarcodeScannerComponent
                  width={400}
                  height={200}
                  onUpdate={(err, result) => {
                    if (result ) { setS({...S, [field.name]: result.text})}  
                  }}
                /> 
                { S[field.name] }
                </>
            }
*/}            
            </div>
            </Grid>
          );
        })}          
    <Grid item xs={12}>
      { onSubmit &&
        <div className={styles.btnArea}>
          <Button variant="contained" disabled={!isValid()} fullWidth color="primary" size="large" type="button" onClick={() => onSubmit(S)}>
            {submitLabel}
            <ArrowForward disabled={!isValid()} />
          </Button>
        </div>
      }
    </Grid>
  </Grid>



    );
}

DynaForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DynaForm;
