import React, { useState, useEffect } from 'react';
import './Bios.css';
import CastList from './CastList';


function App() {
  const [castData, setCastData] = useState([]);


  useEffect(() => {
    // Fetch the cast data from the public folder
    fetch('/castData.json')
      .then((response) => response.json())
      .then((data) => setCastData(data))
      .catch((error) => console.error('Error fetching cast data:', error));
  }, []); // The empty array ensures this effect runs only once on mount

  return (
    <div className="App">
      <header className="App-header">
        <br/>
        <img src='/images/lw_small.jpg' alt={`Little Women Title Image`} />
        <h1>Little Women</h1>
        <h2>Cast and Crew</h2>
      </header>
      <main>
        <CastList cast={castData} />
      </main>
    </div>
  );
}

export default App;
