import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"

import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import {getHostName, makeUrl } from '../../../api';
import moment from 'moment';

function TextFileViewer({meeting, file }) {
  const [fileContent, setFileContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme()
  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block",
    },  
    bordered: {
      border: "2px solid black"
    }
  }


  useEffect(() => {
    fetch(makeUrl(file))
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(text => {
        setFileContent(text);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching text file:', error);
        setError(error);
        setIsLoading(false);
      });
  }, [file]); // Dependency array ensures the effect runs when file changes

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading file: {error.message}</p>;
  }

  return (
    <Card >
      <CardActionArea >
        <Typography noWrap gutterBottom variant="h5" component="div" textAlign={'center'}>
          {`${file.fileType} --- ${file.recType}`}
          <Typography variant="body2" color="text.secondary">{`${moment(file.recStart).format('HH:mm')} - ${moment(file.recStop).format('HH:mm')}`}</Typography>
        </Typography>
        <CardContent >
          <pre style={{ maxHeight: '284px', minHeight: '284px', overflow: 'auto', fontSize: '10px' }}>
            {fileContent}
          </pre>        
        </CardContent>
      </CardActionArea>
    </Card>          
  );
}

export default TextFileViewer;
