import React from 'react';
import { useTheme } from "@mui/material/styles"
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function PapperBlock(props) {
  const {
    classes,
    title,
    desc,
    children,
    whiteBg,
    noMargin,
    colorMode,
    overflowX,
    icon, webpageLink, setMessage
  } = props;

  const onCopyLink = (event, val) => {
    event.stopPropagation();
    event.preventDefault();

    // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value =val;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        setMessage({variant: "info", msg: "Item Copied"});
  }

  const theme = useTheme()

const styles = {
  root: {
    margin: 0
  },
  descBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    }
  },
  titleText: {
    flex: 1
  },
  title: {
    position: 'relative',
    textTransform: 'capitalize',
    fontSize: 24,
    fontWeight: 400,
    color: "white",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontWeight: 600,
      marginBottom: theme.spacing(1)
    }
  },
  description: {
    maxWidth: 960,
    color: "white",
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2)
    }
  },
  whiteBg: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
  noMargin: {},
  colorMode: {
    padding: "10px",
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    '& $title': {
      color: theme.palette.grey[100],
      '&:after': {
        borderBottom: `5px solid ${theme.palette.primary.light}`
      }
    },
    '& $description': {
      color: theme.palette.grey[100],
    }
  },
  overflowX: {
    width: '100%',
    overflowX: 'auto',
  },
  iconTitle: {
    border: theme.palette.type === 'dark' ? 'none' : `1px solid ${theme.palette.primary.dark}`,
    boxShadow: `0 2px 15px -5px ${theme.palette.primary.main}`,
    background: theme.palette.type === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
    width: 48,
    height: 48,
    textAlign: 'center',
    lineHeight: '44px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '& i': {
      fontSize: 28,
      verticalAlign: 'baseline',
      color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.primary.main
    }
  }
}
  return (
    <div>
      <Paper sx={styles.colorMode} elevation={0}>
        <div style={styles.descBlock}>
          <IconButton  color="inherit" aria-label="open drawer" edge="start" >
            {icon}
          </IconButton> 
          <div style={styles.titleText}>
            <Typography sx={styles.title}>{title}</Typography>
            <Typography component="p" sx={styles.description}>{desc}</Typography>
          </div>
        </div>

        <section >
          {children}
        </section>
      </Paper>
    </div>
  );
}

PapperBlock.defaultProps = {
  whiteBg: false,
  noMargin: false,
  colorMode: false,
  overflowX: false,
  icon: 'ion-ios-bookmark-outline'
};

export default PapperBlock;
