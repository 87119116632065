import Phaser from 'phaser';
import { calculatePhotonDirection } from './Mirrors'

import photonImage from "../../../images/photon-007.png"
let F;

// Define Phaser's preload function
export const preload = (scene, f) => {
  F = f;
  // Load photon images 
  scene.load.image('photon', photonImage);
};

// Define Phaser's create function
export const create = (scene) => {

};

function getInitialPhotonDirection(cannonTile) {
  // Assuming 'mirror' is the game object representing the cannon
  const cannon = cannonTile.mirror;

  // Get the rotation of the cannon in degrees
  const rotationDegrees = cannon.angle;

  // Convert degrees to radians for Math.atan2
  const rotationRadians = Phaser.Math.DegToRad(rotationDegrees);

  // Assuming the cannon points right when rotation is 0
  const direction = Math.atan2(Math.sin(rotationRadians), Math.cos(rotationRadians));

  return direction;
}

function getNewPhotonDirection(photon, tile) {
  calculatePhotonDirection(photon, tile);
}

export const firePhoton = (scene, placeTile) => {
  const cannonTile = F.selectedTile;
  const fromX = cannonTile.centerPoint.x;
  const fromY = cannonTile.centerPoint.y;
  const direction = getInitialPhotonDirection(cannonTile);

  const photonLayer = scene.add.container();
  const photon = scene.add.sprite(fromX, fromY, 'photon');
  scene.photon = photon;

  photonLayer.add(photon);
  photonLayer.setDepth(10);

  photon.originKey = cannonTile.key;
  photon.lastMirror = cannonTile.key;
  photon.hasLeftOrigin = false;
  const speed = 200; // Pixels per second

  scene.physics.add.existing(photon);
  scaleImage(F.gridSize, photon, 0.25);

  photon.body.velocity.x = speed * Math.cos(direction);
  photon.body.velocity.y = speed * Math.sin(direction);

  // Reintroduce the collision logic with adjustments
  Object.values(F.tiles).forEach(({ key, x, y, type, zone, centerPoint }) => {
    if (type !== 'blank' && type !== 'empty' && zone === 'battle') {
        scene.physics.add.overlap(photon, centerPoint, function() {
          // Skip the origin tile on the first check
          if (key === photon.originKey && !photon.hasLeftOrigin) { console.log(`${key}, ${photon.hasLeftOrigin}`); return; }
          if (key === photon.lastMirror) { return; }

          photon.hasLeftOrigin = true; // Mark that the photon has left the origin
          photon.lastMirror = key;

          // Change direction upon collision
          const newDirection = calculatePhotonDirection(photon, F.tiles[key]);
          if (newDirection === null) photon.destroy();
          else {
            photon.body.velocity.x = speed * Math.cos(newDirection);
            photon.body.velocity.y = speed * Math.sin(newDirection); 
          }
          
          //placeTile(scene, x, y, 'empty');
      }, null, scene);
    }
  });
};

function getScreenPosition(x,y) {
  return {
    x: x * (F.gridSize+F.gridSpacing) + 25,
    y: y * (F.gridSize+F.gridSpacing) +25
  }
}
function scaleImage(size, image, ratio = 1.0) {
  image.setScale((size / image.width) * ratio, (size / image.height) * ratio);
}



