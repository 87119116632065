import React, { useState, useRef, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';

import { Container } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import {apiV2} from '../../../api';
import MainFrame from "../MainFrame"
import InventoryItemCard from "../../Cards/InventoryItemCard/InventoryItemCard";
import cardImage from "../../../images/contemplative-reptile.jpg"
import TreeNodeSelector from "../../TreeNodeSelector/TreeNodeSelector"
import AddIcon from '@mui/icons-material/Add';

function Inventory() {
  const scrollRef = useRef(null);
  const dataFetchedRef = useRef(false);

  const navigate = useNavigate();

  const [filter, setfilter] = useState({});
  const [tmpFilter, settmpFilter] = useState(filter);
  const [invitems, setinvitems] = useState([]);
  const [filterData, setfilterData] = useState([]);

  const handleOnClick = (url) => navigate(url);
/*
  const onAddLocation = () => {
    apiV2(`location/revise/2663`, 'POST', {values: {title: "New Location", parentId: locationId}}).then(res0 => {
      if (!res0.error) { 
          setlocations((locations) => [...locations, res0.model]);
      }
    });
  }
*/

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    apiV2(`getInventory`).then(res0 => {
      if (!res0.error) { 
        setfilterData(res0.filterData);
        setinvitems(res0.invitems);
      }
    });
   
  }, []);
  
  useEffect(() => {
    /*
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    apiV2(`locations/maint`, 'POST', null).then(res0 => {
      if (!res0.error) { 
        setlocations(res0.model);
      }
    });
   */
  }, [filter]);
  
  /*
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }

  }, [messages]);
*/

  const theme = useTheme()
  const styles = {
    paper: {
      backgroundColor: '#e2ebe2',
    },    
  }


  const setLocation = (nodeId) => {
    const location = filterData.location.find(x=>x._id === nodeId);
    if (!location) delete tmpFilter.location;
    else tmpFilter.location = location.path;
    settmpFilter({...tmpFilter});
  };

  const setCategory = (nodeId) => {
    const category = filterData.category.find(x=>x._id === nodeId);
    if (!category) delete tmpFilter.category;
    else tmpFilter.category = category.path;
    settmpFilter({...tmpFilter});
  };

  const onDrawerClose = () => {
    setfilter({...tmpFilter});
  }

  const onAddInventory = () => {
    navigate("/inventory/edit/0");
  };

  const openMenu = [
    //{title: "Add", handler: onSetFilter}
  ]

  const toolBarMenu = [
    {title: "Add", icon: <AddIcon />, handler: onAddInventory}
  ]

  const sideMenu = [
    <TreeNodeSelector data={filterData.location} rootLabel="Locations" onSelectItem={setLocation}/>,
    <TreeNodeSelector data={filterData.category} rootLabel="Categories" onSelectItem={setCategory}/>    
  ]

  const filteredInvitems = invitems.filter(x=>
    (x.locationPath.startsWith(filter.location) || !filter.location) &&
    (x.categoryPath.startsWith(filter.category) || !filter.category) 
  );
  return (
      <MainFrame pageTitle="Inventory" sideMenu={sideMenu} openMenu={openMenu} toolBarMenu={toolBarMenu} onClose={onDrawerClose}>
          <div ref={scrollRef} ></div>

            {filteredInvitems && filteredInvitems.map((x, lindex) => (
                <InventoryItemCard 
                  image={cardImage} 
                  handleOnClick={()=>handleOnClick(`/inventory/${x._id}`)}
                  title={x.title}
                  desc="Visit the Theater Inventory of Props, Set Pieces, customs, etc ..."
                />
            ))}    

          
      </MainFrame>
  );
}
export default Inventory;

