import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"

import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import DynaForm from "../../Forms/DynaForm";
import moment from "moment";


import {apiV2 } from '../../../api';


function MeetingSelector({filter, onFilterChanged}) {
  const [meetingFilter, setmeetingFilter] = useState(filter ? {...filter} : { 
    account: null, topic: null, 
    year: moment().year(), 
    month: moment().month()+1
   });

   useEffect(() => { setmeetingFilter(filter)}, [filter] )

  return (
    <Card key={'fileterCard'} style={{ maxHeight: '304px', minHeight: '284px', overflow: 'auto' }}>
        <CardContent >
            <DynaForm
              onSubmit={(values) => onFilterChanged(values)}
              submitLabel="Get Matches"
              fields={[
                {
                  name: 'account',
                  defaultValue: meetingFilter.account ,
                  fieldType: 'select',
                  valueType: 'text',
                  placeholder: "Account",
                  label: "Account",
                  size: 12,
                  required:true,
                  options: [ {id: "8TmdQRm5R_CfdksQHFGZ9w", label: "Heather"}, {id: "zI3Kke8IQdWYj0jcNmji8A", label: "Jim"}, {id: "ZDu4rriKTre8emA0fH4yPQ", label: "Lasting Impact"} ]
                },            
                {
                  name: 'topic',
                  defaultValue: meetingFilter.topic,
                  fieldType: 'text',
                  valueType: 'text',
                  placeholder: "Topic",
                  label: "Topic",
                  size: 12,
                },                         
                {
                  name: 'year',
                  defaultValue: meetingFilter.year,
                  fieldType: 'number',
                  valueType: 'number',
                  placeholder: "Year",
                  label: "Year",
                  size: 6,
                },           
                {
                  name: 'month',
                  defaultValue: meetingFilter.month,
                  fieldType: 'number',
                  valueType: 'number',
                  placeholder: "Month",
                  label: "Month",
                  size: 6,
                },           
              ]}
            />    
        </CardContent>
    </Card>          
  );
}

export default MeetingSelector;
