import React, { useState } from 'react';
import { useAlert } from '../AlertContext';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

export default function BasicDataGrid(props) {
  const { columns, filters, data, onRowSelected, height } = props;
  const { notify } = useAlert();

  const [selected, setSelected] = useState(null);

  const gridStyle = { minHeight: height, maxHeight: height };

 return (
  <ReactDataGrid 
    idProperty="_id"
    style={gridStyle}
    pagination
    defaultFilterValue={filters}    
    columns={columns}
    dataSource={data ? data : []}
    enableSelection={true}
    onSelectionChange={(data)=>onRowSelected(data.data)}
  />
 );
}