// CastList.js
import React from 'react';
import './CastList.css';

// CastMember component to display individual cast member details
const CastMember = ({ member }) => {
  return (
    <div className="container">
      <div className="box top-box">{member.name}</div>
        <div className="box image-box">
          <img src={`/${member.imageUrl}`} alt={`Portrait of ${member.name}`} />
        </div>
      <div className="box bio-box">{member.biography}</div>
    </div>
  );
};

// Main component to display all cast members
const CastList = ({ cast }) => {
  return (
    <div>
      {cast.map((member, index) => (
        <CastMember key={index} member={member} />
      ))}
    </div>
  );
};

export default CastList;
