import React, { useState, useRef, useEffect } from "react";
import Phaser from 'phaser';

import {useNavigate} from 'react-router-dom';

import { Container } from "@mui/material"

import MainFrame from "../MainFrame"
import {preload as preloadMap, create as createMap } from "./Map"
import {indicateTurn, placeTile as placeTileCache, getTile as getTileCache } from "./MapCache"
import {placeTile as placeTileBattle, getTile as getTileBattle } from "./MapBattle"
import { togglePlacingTile } from "./MapCommand"
import {preload as preloadPhoton, create as createPhoton, firePhoton } from "./Photon"

function Flector() {

  const phaserGame = useRef(null);
  const gameContainerRef = useRef(null);
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  
  const navigate = useNavigate();

  const styles = {    
    fullscreen: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#000000',
    },
  }
  const gridSpacing = 1;
  const gridSize = Math.min((screenWidth-36)/10,(screenHeight-36)/15) - gridSpacing;
  const gridMarginX = (screenWidth - ((gridSize + gridSpacing) * 10)) / 2;
  const gridMarginY = (screenHeight - ((gridSize + gridSpacing) * 15)) / 2;
  const F = {
    tiles: {},
    mirrors: {},
    selectedTile: null,  // Track the currently selected map tile
    placingType: null,
    gridSize: gridSize,
    gridSpacing: gridSpacing,
    gridMarginX: gridMarginX,
    gridMarginY: gridMarginY,
    mapXs: 10,
    mapYs: 10,
    cacheSize: 10,
    commandXs: 8,
    commandYs: 1,
    playerCannonCount: 1,
    playerCount: 4,
    players: [{name: 'system'}, {name: 'test1'}, {name: 'test2'}, {name: 'test3'}, {name: 'test4'}],
    currentPlayer: 0,
    turnCount: 0,
    isPlacing: false,
  }


  // Define Phaser's preload function
  const preload = function() {
    // Load map images 
    preloadMap(this, F);
    preloadPhoton(this, F);
  };

  // Define Phaser's create function
  const create = function() {
    createMap(this, (tile) => onSelectedTileChanged(this, tile), (command) => onCommand(this, command));
    createPhoton(this);
  };

  const update = function() {
    if (this.photon && this.photon.active) {
      if (
        this.photon.x < 4 || 
        this.photon.x > F.mapXs * (F.gridSize+F.gridSpacing) || 
        this.photon.y < 4 || 
        this.photon.y > F.mapYs * (F.gridSize+F.gridSpacing)) {
          this.photon.destroy();
      }
    }
  };

  useEffect(() => {
    const config = {
        type: Phaser.AUTO,
        width:  screenWidth,
        height: screenHeight,
        parent: gameContainerRef.current,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        physics: {
          default: 'arcade',
          arcade: {
              gravity: { y: 0 }, // No gravity in any direction
              debug: false, // Set to true if you want to see physics debug information
          },
        },        
        scene: { preload, create, update },
        
    };

    phaserGame.current = new Phaser.Game(config);

    // Window resize event handler
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      // Adjust game size or container size
      phaserGame.current.scale.resize(800, 1000);
    };

    // setup Mirrors
    F.mirrors.zorbflector = {name: 'zorbflector', isAllowed: true };
    F.mirrors.bender = {name: 'bender', isAllowed: true };
    F.mirrors.transflector = {name: 'transflector', isAllowed: true };
    F.mirrors.transzorber = {name: 'transzorber', isAllowed: true };
    F.mirrors.transflector = {name: 'transflector', isAllowed: true };
    F.mirrors.zorber = {name: 'zorber', isAllowed: true };
    F.mirrors.cannon = {name: 'cannon', isAllowed: false };
  
    // Add event listener
    //window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      phaserGame.current.destroy(true);
    };
  }, []);

  const setSelectedTile = (tile) => {
    F.selectedTile = tile;
    console.log("selected:");
    console.log(F.selectedTile);
  };

  const onSelectedTileChanged = (scene, t) => {
    setSelectedTile(t);
  };

  const onCommand = (scene, command) => {
    if (!command) return;

    switch (command) {
        case 'rotateLeft':
            if (!F.selectedTile) return;
            if (F.selectedTile.zone !== 'battle' || F.selectedTile.owner !== F.currentPlayer) return;
            F.selectedTile.mirror.angle -= 45;
            break;
        case 'rotateRight':
            if (!F.selectedTile) return;
            if (F.selectedTile.zone !== 'battle' || F.selectedTile.owner !== F.currentPlayer) return;
            F.selectedTile.mirror.angle += 45;
            break;
        case 'delete':
            if (!F.selectedTile) return;
            if (F.selectedTile.owner !== F.currentPlayer) return; 
            placeTileBattle(scene, F.selectedTile.x, F.selectedTile.y, 'empty')
            break;
        case 'fire':
            if (!F.selectedTile) return;
            if (F.selectedTile.zone !== 'battle') return;
            if (F.selectedTile.type !== 'cannon') return;
            if (F.selectedTile.owner !== F.currentPlayer) return; 
            firePhoton(scene, placeTileBattle);                          
            break;
        case 'place':
            F.isPlacing = !F.isPlacing;
            togglePlacingTile(scene, F.isPlacing)
            break;            
        case 'turn':
            F.currentPlayer = F.currentPlayer + 1;
            if (F.currentPlayer > F.playerCount) F.currentPlayer = 1;
            transitionTurn(scene);
            break; 
    }
  };

  function transitionTurn(scene) {
    indicateTurn(scene);
    if (F.currentPlayer === 1) F.turnCount+=1;

    // fill up players slate based on turnCount
    for(let x=1; x<=F.cacheSize-1; x+=1) {
      if (x > F.turnCount) continue;
      const tile = getTileCache(x, F.currentPlayer);
      if (tile && tile.type === 'empty') {
        placeTileCache(scene, x, F.currentPlayer, selectRandomAllowedMirror().name, F.currentPlayer)
      }
    }
  }

  function selectRandomAllowedMirror() {
    // Filter to get only mirrors that are allowed
    const allowedMirrors = Object.values(F.mirrors).filter(mirror => mirror.isAllowed);

    // Randomly select one of the allowed mirrors
    const randomIndex = Math.floor(Math.random() * allowedMirrors.length);
    return allowedMirrors[randomIndex];
  }

  return (
    <Container sx={styles.fullscreen}>
      <div ref={gameContainerRef} id="gameContainer" />
    </Container>        
  );
}
export default Flector;


