import React, { useState, useEffect, useRef } from 'react';

import {Chip, TextField, Button, IconButton, InputAdornment, Divider} from '@mui/material'
import {Close, AddCircle} from '@mui/icons-material';
import { useTheme } from "@mui/material/styles"

import OutlinedDiv from './OutlinedDiv';
//import styles from './cardStyle-jss';

function TagCard(props) {
  const {
    classes,
    tagArray,
    isAdmin,
    readOnly,
    onChange,
    label,
    isEditing,
    canSave,
    save
  } = props;
  
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState(tagArray);
  
  const theme = useTheme()
  
  const styles = {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    rightChip: {
      margin: theme.spacing(0.5),
      paddingLeft: '13px',
    },
  };


  useEffect(() => { setTags(tagArray)}, [tagArray] )




  const handleDelete = (chipToDelete) => () => {
    const newTags = tagArray.filter((chip) => chip !== chipToDelete);
    setTags(newTags);
    if (onChange) onChange(newTags)
  };

  const handleChange = (e) => {
    if (e.target.value.slice(-1).charCodeAt(0) === 10) {
      e.preventDefault();

      tags.push(newTag.toUpperCase());
      setTags(tags)
      if (onChange) onChange(tags)

      tagInput.current.value = "";
      handleChange(e); // to force the render
    }
    else setNewTag(e.target.value) 
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = shouldSave => e => {
    setOpen(false);
  };

  let tagInput = useRef(null);

  return (
    <div>
      <OutlinedDiv label={label}>
      {open &&
        <div>                                                         
          <TextField
            inputRef={tagInput}
            autoFocus
            margin="dense"
            id="tag"
            label="Tag"
            type="text"
            multiline={true}
            onChange={handleChange}
            InputProps={{startAdornment: <InputAdornment position="start"><IconButton color="secondary" onClick={handleClose(false)} ><Close /></IconButton></InputAdornment>}}
            sx={{marginTop: "-13px", marginLeft: "52px"}}
          />    
        </div>
        }        
        {isEditing && 
        <Chip sx={styles.rightChip}
          icon={<AddCircle />}
          onClick={handleClickOpen}
        />
        }
        {tags && tags.map((x,i) => {
          if (x) {
            return (
                <Chip sx={styles.chip}
                  key={`chip_${x}`}
                  label={x}
                  onDelete={isAdmin ? handleDelete(x) : null}
                />
            );
          }
        })}
   
      </OutlinedDiv>
      {isEditing && canSave && <>
        <br/>
        <br/>
        <Divider />
        <Button size="small" onClick={() => save({tags: tags})} >Save</Button>
        </>}
  </div>

  );
}

export default TagCard;
/*

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogContent>
        <TextField
          inputRef={tagInput}
          autoFocus
          margin="dense"
          id="tag"
          label="Tag"
          type="text"
          fullWidth
          multiline={true}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
*/