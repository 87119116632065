import React, { useState, useRef, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';

import { Container, Button, Grid } from "@mui/material"

import { useTheme } from "@mui/material/styles"
import {apiV2 } from '../../../api';

import MainFrame from "../MainFrame"
import { Mail as MailIcon  } from '@mui/icons-material';
import MeetingSelector from "./MeetingSelector"
import BasiceDataGrid from "../../Grids/BasicDataGrid";

import "./styles.css";

import moment from 'moment'

window.moment = moment;

function MeetingMover() {

  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [filter, setfilter] = useState({ 
    account: null, topic: null, 
    year: moment().year(), 
    month: moment().month()+1
   });
  const [meetings, setmeetings] = useState(null);
  
  const handleOnClick = (url) => navigate(url);
  
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    apiV2(`meetings`, 'POST', {filter: {isMoved: false}},{sortBy: 'startDate', order: 'Asc'}).then(res0 => {
      if (!res0.error) setmeetings(res0);
      console.log(res0)

    });
   
  }, []);

  const theme = useTheme()
  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block"
    },  
    bordered: {
      border: "2px solid black"
   }
  }
  

  const menu = [
    {title: "List Meetings", icon: <MailIcon />, handler: ()=>navigate("/meetingmanager")}
  ];

  const onRowSelected = (meeting) => {
    //setmeeting(meeting);
    //if (!file || file.fileType !== 'EDIT') setfile(null);
  };    

  const onMove = () => {
    apiV2(`moveRecordings`, 'POST').then(res0 => {
      if (res0.error) {console.log(res0.error)} //setMessage({variant: "error", msg: res0.error});
      else {
        //setMessage({variant: "info", msg: "Esca event Saved"});
        setmeetings([]);
      }
    });
  };    

  const columns = [
    { name: '_id', header: 'Id', minWidth: 40, defaultVisible: false, defaultFlex: 2 },
    { name: 'topic', header: 'Topic', maxWidth: 1000, defaultFlex: 1 },
    {
      name: 'startDate',
      header: 'Date',
      defaultFlex: 1,
      minWidth: 200,
      render: ({ value, cellProps }) => {
        return moment(value).format('YYYY-MM-DD  HH:mm')
      }
    },
    { name: 'duration', header: 'Length', type: 'number', minWidth: 40, maxWidth: 100, defaultFlex: 1 },
    { name: 'fileCount', header: 'Files', type: 'number', minWidth: 40, maxWidth: 76, defaultFlex: 1 },
    { name: 'fileSize', header: 'Size', type: 'number', minWidth: 40, maxWidth: 1000, defaultFlex: 1 },
  ];
  
  const filters = [
    { name: 'topic', operator: 'contains', type: 'string', value: '' },
    { name: 'startDate', operator: 'contains', type: 'string', value: ''},
  ]


  return (
    <MainFrame pageTitle="Glance Theater Manager" sideMenu={menu}>
      <Container sx={styles.fullscreen}>
        <Grid container justify="flex-start" direction="row" spacing={1} >
          <Grid item md={12}>
            <BasiceDataGrid columns={columns} filters={filters} data={meetings ? [...meetings] : []} onRowSelected={onRowSelected} height={'840px'}></BasiceDataGrid>       
            <Button size="small" sx={styles.buttonLink} onClick={onMove}>CONVERT AND MOVE FILES TO SPACES</Button>
          </Grid>
        </Grid>
      </Container>
    </MainFrame>
  );
}
export default MeetingMover;
  