import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function MeetingCard(props) {
    const {handleOnClick, meeting} = props;

    const styles = {
      paper: {
        width: "420px",
        margin: "10px",
        display: "inline-block"
      },    
    }    
  
  return (
    <Card sx={styles.paper}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="24"
          onClick={handleOnClick}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {meeting.topic}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${meeting.fileCount}   ---   ${meeting.fileSize}  ---    ${meeting.startDate}`}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

