import Phaser from 'phaser';

export const calculatePhotonDirection = (photon, tile) => {
  const incomingDirection = getDirectionFromVelocity(photon.body.velocity);
  const { hitType, relativeAngle } = determineHitType(tile, photon);

  switch (hitType) {
    case 'reflect': return calculateReflection(incomingDirection, relativeAngle);
    case 'bend': return calculateBend(incomingDirection, relativeAngle);
    case 'passthrough': return incomingDirection;
    case 'absorb': return null;
    case 'edge': return null;
  }
  
}

function getDirectionFromVelocity(velocity) {
  // Convert velocity to angle or use any suitable method to determine direction
  return Math.atan2(velocity.y, velocity.x);
}

function determineHitType(tile, photon) {
  const mirror = tile.mirror;
  const mirrorRotation = Math.round(Phaser.Math.RadToDeg(mirror.rotation)) % 360; // Convert to degrees for ease of calculation
  const photonDirection = Math.round(Phaser.Math.RadToDeg(Math.atan2(photon.body.velocity.y, photon.body.velocity.x))) % 360;
  const relativeAngle = (photonDirection - mirrorRotation + 360) % 360;
  console.log(`mirRot: ${mirrorRotation}, photonDir: ${photonDirection}, relativeAngle: ${relativeAngle}`)

  if (tile.type === 'cannon') return { hitType: 'absorb', relativeAngle: relativeAngle };
  if (tile.type === 'zorber') return { hitType: 'absorb', relativeAngle: relativeAngle };
  if (tile.type === 'bender') return { hitType: 'bend', relativeAngle: relativeAngle };

  // standard two sided mirrors
  let top = 'reflect';
  let bot = 'reflect';

  if (tile.type === 'flector') { top = 'reflect'; bot = 'reflect' };
  if (tile.type === 'transflector') { top = 'passthrough'; bot = 'reflect' };
  if (tile.type === 'transzorber') { top = 'passthrough'; bot = 'absorb' };
  if (tile.type === 'zorbflector') { top = 'absorb'; bot = 'reflect' };

  if (relativeAngle <= 135 && relativeAngle >= 45) return { hitType: top, relativeAngle: relativeAngle };
  else if (relativeAngle >= 225 && relativeAngle <= 315) return { hitType: bot, relativeAngle: relativeAngle };
  else return { hitType: 'edge', relativeAngle: relativeAngle };

}

function calculateReflection(incomingDirection, relativeAngle) {
  console.log(incomingDirection, relativeAngle);
  let newDirection = incomingDirection;

  // Adjust the reflection based on the transflector's orientation
  if (relativeAngle === 225) { newDirection = incomingDirection - Math.PI / 2; }
  if (relativeAngle === 270) { newDirection = incomingDirection + Math.PI ; }
  if (relativeAngle === 315) { newDirection = incomingDirection + Math.PI / 2; }

  // Adjust the reflection based on the transflector's orientation
  if (relativeAngle === 45) { newDirection = incomingDirection - Math.PI / 2; }
  if (relativeAngle === 90) { newDirection = incomingDirection + Math.PI ; }
  if (relativeAngle === 135) { newDirection = incomingDirection + Math.PI / 2; }

  if (newDirection < 0) { newDirection += (2 * Math.PI); } // Ensure the angle stays within the range of 0 to 2π  
  if (newDirection >= 2 * Math.PI) { newDirection -= 2 * Math.PI; } // Normalize if the angle exceeds 2π    

  return newDirection;
}


function calculateBend(incomingDirection, relativeAngle) {
  console.log(incomingDirection, relativeAngle);
  const BENDER_ANGLE = Math.PI / 4; // 45 degrees in radians

  if (relativeAngle !== 180 && relativeAngle !== 45) return null;

  // Adjust incoming direction by the Bender angle
  // The direction is adjusted based on the relative angle of the mirror
  let newDirection = incomingDirection + (relativeAngle === 180 ? BENDER_ANGLE : -BENDER_ANGLE);

  // Normalize the outgoing direction to keep it within 0 to 2π radians
  newDirection = (newDirection + 2 * Math.PI) % (2 * Math.PI);



/*

-45, 180 = 0
0, 180 = 45
45, 180 = 90
90, 180 = 135
135, 180 = 180
-180, 180 = 225
-135, 180 = 270
-90, 180 = 315

-45, 45 = 270
-90, 45 = 225
*/
/*
  if (newDirection < 0) { newDirection += (2 * Math.PI); } // Ensure the angle stays within the range of 0 to 2π  
  if (newDirection >= 2 * Math.PI) { newDirection -= 2 * Math.PI; } // Normalize if the angle exceeds 2π    
*/
  return newDirection;
}


