import { buildTile, destroyTile } from "./Tile"
import { placeTile as placeTileCache } from "./MapCache"
import { togglePlacingTile } from "./MapCommand"

let F;
let originX = 0;
let originY = 0;
let mapXs = 0;
let mapYs = 0;
let highlight; // Graphics object for highlighting
let onSelectionChange; // when a tile is selected call this function

// Define Phaser's preload function
export const preload = (scene, f) => {
  F = f;
};

// Define Phaser's create function
export const create = (scene, osc, oX, oY) => {
  onSelectionChange = osc;
  originX = oX;
  originY = oY;
  mapXs = F.mapXs;
  mapYs = F.mapYs;

  // Create the grid
  for (let x = 0; x < mapXs; x=x+1) {
      for (let y = 0; y < mapYs; y=y+1) {
        placeTile(scene, x, y, 'empty');
      }
  }

  let xMid = Math.floor(mapXs / 2);
  let yMid = Math.floor(mapYs / 2);

  placeTile(scene, xMid, yMid, 'zorber'); 

  if (xMid * 2 === mapXs) { 
    if (yMid * 2 === mapYs) { 
      placeTile(scene, xMid-1, yMid, 'zorber');
      placeTile(scene, xMid, yMid-1, 'zorber'); 
      placeTile(scene, xMid-1, yMid-1, 'zorber');
    } else {
      placeTile(scene, xMid-1, yMid, 'zorber');
    }
  } else {
    if (yMid * 2 === mapYs) { 
      placeTile(scene, xMid, yMid-1, 'zorber'); 
    }
  }

  placeTile(scene, xMid, 1, 'zorber');
  placeTile(scene, xMid, mapYs-2, 'zorber');
  placeTile(scene, 1, yMid, 'zorber');
  placeTile(scene, mapXs-2, yMid, 'zorber');

  if (xMid * 2 === mapXs) { 
    placeTile(scene, xMid-1, 1, 'zorber');
    placeTile(scene, xMid-1, mapYs-2, 'zorber');
  }
  
  if (yMid * 2 === mapYs) { 
    placeTile(scene, mapXs-2, yMid-1, 'zorber');
    placeTile(scene, 1, yMid-1, 'zorber');
  }

  if (F.playerCount >= 1) placeTile(scene, 1, 1, 'cannon', 1);
  if (F.playerCount >= 2) placeTile(scene, F.mapXs-2, F.mapYs-2, 'cannon', 2);
  if (F.playerCount >= 3) placeTile(scene, F.mapXs-2, 1, 'cannon', 3);
  if (F.playerCount >= 4) placeTile(scene, 1, F.mapYs-2, 'cannon', 4);

  highlight = scene.add.graphics({ lineStyle: { width: 4, color: 0xff69b4 } }); // Customize the highlight style
};


export const placeTile = (scene, x, y, typeIn, o = 0) => {
  const key = `battle: ${x}-${y}`;
  const existingTile = F.tiles[key];
  if (existingTile) { destroyTile(scene, existingTile); }

  let zone = 'battle';
  let owner = o; 
  let type = typeIn;

  if (type === 'blank') return null;

  const { background, mirror, centerPoint, overlay } = buildTile(scene, F, type, key, originX+x, originY+y, function () {
      const tile = F.tiles[this.getData('key')];
      if (F.placingType) { // TODO: can i place a mirror on top of another?
        placeTile(scene, tile.x, tile.y, F.placingType);
        F.placingType = null;
        return;
      }
      highlight.clear();
      highlight.strokeRect(this.x-26 , this.y-26, (F.gridSize+F.gridSpacing)+2, (F.gridSize+F.gridSpacing)+2);

      if (F.isPlacing && F.selectedTile.zone === 'cache') {
        placeTile(scene, tile.x, tile.y, F.selectedTile.type, F.currentPlayer);
        placeTileCache(scene, F.selectedTile.x, F.selectedTile.y, 'empty');
        F.isPlacing = false;
        togglePlacingTile(scene, F.isPlacing)
      }

      if (onSelectionChange) onSelectionChange(tile);
  });   
  
  F.tiles[key] = {
    key: key, zone: zone, owner: owner, type: type, x: x, y: y, selected: false, 
    background: background, mirror: mirror, centerPoint: centerPoint, overlay: overlay
  }

  return F.tiles[key];
};

export const toggleTile = (scene, x, y, onoff) => {
    const key = `battle: ${x}-${y}`;
    const tile = F.tiles[key];
    if (tile) tile.overlay.setAlpha(onoff ? 0.75 : 0.0);
};

export const getTile = (x,y) => {
  const key = `battle: ${x}-${y}`;
  return F.tiles[key];
};


