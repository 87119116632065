import { buildTile, destroyTile } from "./Tile"

import rotateLeftImage from "../../../images/rotateLeft.png"
import rotateRightImage from "../../../images/rotateRight.png"
import deleteImage from "../../../images/delete.png"
import fireImage from "../../../images/fire.png"
import placeImage from "../../../images/place.png"
import turnImage from "../../../images/turn.png"

let F;
let originX = 0;
let originY = 0;
let highlight; // Graphics object for highlighting
let onCommand; // when a command tile is clicked

// Define Phaser's preload function
export const preload = (scene, f) => {
  F = f;
  
  // Load images 
  scene.load.image('rotateLeft', rotateLeftImage);
  scene.load.image('rotateRight', rotateRightImage);
  scene.load.image('delete', deleteImage);
  scene.load.image('fire', fireImage);      
  scene.load.image('place', placeImage);      
  scene.load.image('turn', turnImage);      

};

// Define Phaser's create function
export const create = (scene, oc, oX, oY) => {
  onCommand = oc;
  originX = oX;
  originY = oY;

  // Create the grid
  placeTile(scene, 0, 0, 'rotateLeft');
  placeTile(scene, 1, 0, 'rotateRight');
  placeTile(scene, 2, 0, 'place');
  placeTile(scene, 3, 0, 'blank');
  placeTile(scene, 4, 0, 'delete');
  placeTile(scene, 5, 0, 'fire');
  placeTile(scene, 6, 0, 'blank');
  placeTile(scene, 7, 0, 'turn');

  highlight = scene.add.graphics({ lineStyle: { width: 4, color: 0xff69b4 } }); // Customize the highlight style
};


export const placeTile = (scene, x, y, type) => {
  const key = `command: ${x}-${y}`;
  const existingTile = F.tiles[key];
  if (existingTile) { destroyTile(scene, existingTile); }

  let zone = 'command';
  let owner = 0; 

  if (type === 'blank') return null;
  
  const { background, mirror, centerPoint, overlay } = buildTile(scene, F, type, key, originX+x, originY+y, function () {
    const tile = F.tiles[this.getData('key')];
      tile.overlay.setAlpha(0.75);
      if (tile.type !== 'place') {
        // Start a timer to revert the alpha after 0.5 seconds
        scene.time.addEvent({
          delay: 100, // delay in milliseconds (500ms = 0.5s)
          callback: () => {
              tile.overlay.setAlpha(0.0); // Revert to fully opaque
          },
          callbackScope: this
        });
      }
      if (onCommand) onCommand(tile.type);
  });   
  
  // store in tiles
  F.tiles[key] = {
    key: key, zone: zone, owner: owner, type: type, x: x, y: y, selected: false, 
    background: background, mirror: mirror, centerPoint: centerPoint, overlay: overlay
  }

  return F.tiles[key];
};

export const togglePlacingTile = (scene, onoff) => {
  const key = `command: 2-0`;
  const tile = F.tiles[key];
  if (tile) tile.overlay.setAlpha(onoff ? 0.75 : 0.0);
};

export const getTile = (x,y) => {
  const key = `command: ${x}-${y}`;
  return F.tiles[key];
};



