import React, { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles"
import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import moment from 'moment';
import {getHostName, makeUrl } from '../../../api';

function MeetingVideoViewer({meeting, file }) {
  const videoRef = useRef(null);

  const theme = useTheme()
  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block"
    },  
    bordered: {
      border: "2px solid black"
    },
    extraPad: {
      marginRight: '8px',
      marginBottom: '8px',
    }
  }

  return (
    <Card >
      <CardActionArea>
        <Typography noWrap gutterBottom variant="h5" component="div" textAlign={'center'}>
        {`${file.fileType} --- ${file.recType}`}
          <Typography variant="body2" color="text.secondary">{`${moment(file.recStart).format('hh:mm')} - ${moment(file.recStop).format('hh:mm')}`}</Typography>
        </Typography>
        <CardContent>
          <video key={makeUrl(file)} ref={videoRef} width="100%" controls preload="auto" autoPlay>
            <source src={makeUrl(file)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </CardContent>
      </CardActionArea>
    </Card>  
  );
}

export default MeetingVideoViewer;
