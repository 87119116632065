import React, { useState } from "react";

import {useNavigate, Link} from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import {Box, Grid, SwipeableDrawer, CssBaseline, AppBar as MuiAppBar, Toolbar, List, Typography, Divider, IconButton}  from '@mui/material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { Menu as MenuIcon, Mail as MailIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon  } from '@mui/icons-material';

import {apiV2, isLoggedIn } from '../../api';
import Login from "./User/Login";

const drawerWidth = 340;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
    const { pageTitle, sideMenu, openMenu, toolBarMenu, onClose, children } = props;

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const defaultToolBarMenu = [
    {title: "Home", icon: <MailIcon />, handler: ()=>navigate("/")},
    {title: "Flector", icon: <MailIcon />, handler: ()=>navigate("/flector")}
  ]

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography sx={{ display: "flex", alignItems: "center"}} variant="h6" noWrap component="div">
                                {pageTitle}
                            </Typography>
                        </Box>
                        </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <Login mode=''></Login>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
      
        <SwipeableDrawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
            }}
            anchor="left"
            open={open}
        >

        <DrawerHeader>
            {openMenu && openMenu.map((x, lindex) => (
                <IconButton  key={`omib_${lindex}`}  onClick={x.handler}>{x.title}</IconButton>            
            ))}

            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </DrawerHeader>

        <Divider />
        
        <List>
        {sideMenu && sideMenu.map((x, lindex) => (
            <ListItem key={`smli_${lindex}`} disablePadding>
            <ListItemButton onClick={x.handler}>
                <ListItemIcon>
                    {x.icon ? x.icon : x.title}
                </ListItemIcon>
                <ListItemText primary={x.title} />
            </ListItemButton>
            </ListItem>
        ))}
        </List>

        <Divider />

        <List>
            {defaultToolBarMenu.map((x, lindex) => (
                <ListItem key={`smli_${lindex}`} disablePadding>
                <ListItemButton onClick={x.handler}>
                    <ListItemIcon>
                        {x.icon ? x.icon : x.title}
                    </ListItemIcon>
                    <ListItemText primary={x.title} />
                </ListItemButton>
                </ListItem>
            ))}
        </List>

      </SwipeableDrawer>
      
        <div>
            <DrawerHeader />
            {children}  
        </div>

    </Box>
  );
}