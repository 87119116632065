import React from "react";

import {TextField} from '@mui/material'

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
  return (
    <TextField
      style={{width: "100%"}}
      multiline={true}
      label={label}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        style: {padding: "6px"}
      }}
      inputProps={{ children: children }}
    />
  );
};
export default OutlinedDiv;