import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AlertProvider } from './Components/AlertContext';

import './index.css';
import App from './Components/Pages/App/App';
import Missing from './Components/Pages/Missing/Missing';
import Inventory from './Components/Pages/Inventory/Inventory';
import EditInventory from './Components/Pages/EditInventory/EditInventory';
import ViewInventory from './Components/Pages/ViewInventory/ViewInventory';
import Bios from './Components/Pages/Bios/Bios';
import MeetingStudio from './Components/Pages/MeetingStudio/MeetingStudio';
import MeetingManager from './Components/Pages/MeetingManager/MeetingManager';
import MeetingMover from './Components/Pages/MeetingManager/MeetingMover';
import Flector from './Components/Pages/Flector/Flector';
import Prompter from './Components/Pages/Blogger/Prompter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AlertProvider>

  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        
        <Route exact path="/bios/:showId" element={<Bios />} />
        <Route exact path="/flector" element={<Flector />} />

        <Route exact path="/recordings" element={<MeetingStudio />} />
        <Route exact path="/meetingmanager" element={<MeetingManager />} />
        <Route exact path="/meetingmover" element={<MeetingMover />} />
        
        <Route exact path="/prompter" element={<Prompter />} />
        
        <Route exact path="/inventory" element={<Inventory />} />
        <Route path="/inventory/edit/:invitemId" element={<EditInventory />} />
        <Route path="/inventory/:invitemId" element={<ViewInventory />} />
        <Route path='*' element={<Missing />}/>
      </Routes>

    </Router>
  </React.StrictMode>
  </AlertProvider>
);


