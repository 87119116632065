import React, { useEffect, useState } from 'react';
import { useAlert } from '../../AlertContext';

import { Dialog, DialogTitle, Paper, Button, Typography, Alert, capitalize } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { NavLink } from 'react-router-dom';
import DynaForm from "../../Forms/DynaForm"

import {apiV2, storeJWT, isLoggedIn } from '../../../api';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

export default function Login(props) {
  const { mode } = props;
  const [modeS, setmodeS] = useState(mode);
  const { notify } = useAlert();

  const theme = useTheme()

  const styles = {    
      paper: {
          width: "calc(100vw - 10)",
          height: "calc(100vh - 10)",
          backgroundColor: '#e2ebe2',
          padding: '10px',
      },  
      buttonLink: {
        background: 'none',
        padding: 0,
        textTransform: 'none',
        transition: 'color ease 0.3s',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '0.875rem',
        '&:hover': {
          background: 'none',
          color: theme.palette.secondary.main
        }
      },
      topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        '& $icon': {
          marginRight: theme.spacing(1)
        },
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
          marginBottom: theme.spacing(3)
        }
      },  
      title: {
        color: theme.palette.primary.main,
      },
      subtitle: {
        fontSize: 14
      },
      dialogTitle: {
        textTransform: 'capitalize'
      }
  }


  const [S, setS] = useState(null);

  const sendCode = values => {
    //router.post("/signout/:authUserId", signin);
    apiV2(`sendAccountCreationCode`, 'POST', values).then(res0 => {
      setS(values);
      if (res0.error) {
        console.log(res0.error);
        notify('error', res0.error)
      } else {
        notify('info', res0.message)
      }
    });    

  };
  const submitLogout = values => {
    apiV2(`signout`).then(res0 => {
      if (res0.error) {
          console.log(res0.error);
          notify('error', res0.error)
        } else {
          console.log(res0)
          if (typeof window !== "undefined") {
            storeJWT(null,  () => {
              window.location.href = "/";
          })
        }
      }
    }); 
  }

  const submitLogin = values => {
    // router.post("/signout/:authUserId", signin);
    apiV2('auth/signin', 'POST', values).then(res0 => {
      if (res0.error) {
        console.log(res0.error);
        notify('error', res0.error)        
      } else {
        console.log(res0);
        storeJWT(res0, () => {
          // users are requesting not to be taken to the dashboard
          // this.props.history.push(`${process.env.PUBLIC_URL}`);
          //window.location.href = '/pages/userprofile';
          window.location.href = "/";
          // this.props.onHeaderDataChanged();
        });
        setmodeS('');
      }
    });
  };

  const submitRegister = values => {
    //router.post("/signout/:authUserId", signin);
    apiV2(`register`, 'POST', values).then(res0 => {
      if (res0.error) {
          console.log(res0.error);
          notify('error', res0.error)
        } else {
          console.log(res0)
          storeJWT(res0, () => {
              // users are requesting not to be taken to the dashboard
              //this.props.history.push(`${process.env.PUBLIC_URL}`);      
              //window.location.href = '/pages/userprofile';
              //this.props.onHeaderDataChanged();
          });
      }
    });    
  };

  const handleClose = () => {
    setmodeS('');
  };

  return (<>
        {modeS === '' ? <Paper sx={styles.paper}>
        {isLoggedIn() ?
           <Button size="small" sx={styles.buttonLink} onClick={()=>{submitLogout()}}>Logout</Button>
        :  <Button size="small" sx={styles.buttonLink} onClick={()=>{setmodeS('login')}}>Login</Button>
        }
          </Paper> :

        <Dialog onClose={handleClose} open={modeS!==''}>
          <DialogTitle sx={styles.dialogTitle}>{modeS}</DialogTitle>

          <Paper sx={styles.paper}>
          <div style={styles.topBar}>
            {modeS === 'register' && <Button size="small" sx={styles.buttonLink} onClick={()=>{setmodeS('login')}}>Already have account?</Button>}
            {modeS === 'login' && <Button size="small" sx={styles.buttonLink} onClick={()=>{setmodeS('register')}}>Create new account?</Button>}
            <Button size="small" sx={styles.buttonLink} onClick={()=>{setmodeS('')}}>Cancel</Button>
          </div>
          
            {modeS === 'login' && 
            <DynaForm
              onSubmit={(values) => submitLogin(values)}
              submitLabel="Login"
              fields={[
                {
                  name: 'email',
                  defaultValue: 'jim@lastingimpact.info',
                  fieldType: 'text',
                  valueType: 'email',
                  placeholder: 'Your Email',
                  label: 'Your Email',
                  size: 12,
                  required: true
                }, {
                  name: 'password',
                  defaultValue: '',
                  fieldType: 'secret',
                  valueType: 'password',
                  placeholder: 'Your Password',
                  label: 'Your Password',
                  size: 12,
                  required: true
                }, {
                  name: 'forgotPassword',
                  fieldType: 'link',
                  url: '/reset-password',
                  label: 'Forgot Password',
                  size: 12,
                }
              ]}
            />
            }

            {modeS === 'register' && 
            <DynaForm onSubmit={(values) => submitRegister(values)} submitLabel="Register"  fields={[
              {
                name: 'firstname',
                defaultValue: S ? S.firstname : '',
                fieldType: 'text',
                valueType: 'text',
                placeholder: "First name",
                label: "First name",
                size: 6,
                required:true
              },{
                name: 'lastname',
                defaultValue: S ? S.lastname : '',
                fieldType: 'text',
                valueType: 'text',
                placeholder: "Last name",
                label: "Last name",
                size: 6,
                required:true
              },{
                name: 'email',
                defaultValue: S ? S.email : '',
                fieldType: 'text',
                valueType: 'email',
                placeholder: "Email Address",
                label: "Email Address",
                size: 12,
                required:true
              },{
                name: 'phone',
                defaultValue: S ? S.phone : '',
                fieldType: 'text',
                valueType: 'text',
                placeholder: "Phone Number",
                label: "Phone Number",
                size: 4,
                required:true
              },{
                name: 'sendCode',
                fieldType: 'command',
                valueType: 'text',
                label: "Send Code",
                onClick: sendCode,
                size: 3,
                required:true
              },{
                name: 'code',
                defaultValue: S ? S.code : '',
                fieldType: 'text',
                valueType: 'text',
                placeholder: "Received Code",
                label: "Received Code",
                size: 5,
                required:true
              },{
                name: 'password',
                defaultValue: S ? S.password : '',
                matchingField: 'confirmPassword',
                fieldType: 'secret',
                valueType: 'password',
                placeholder: "Password",
                label: "Password",
                size: 6,
                required:true
              },{
                name: 'confirmPassword',
                defaultValue: S ? S.confirmPassword : '',
                matchingField: 'password',
                fieldType: 'secret',
                valueType: 'confirmPassword',
                placeholder: "Confirm Password",
                label: "Confirm Password",
                size: 6,
                required:true
              }
            ]}/>
          }
        </Paper>
      </Dialog>
  }
  </>);
}

