import { buildTile, destroyTile } from "./Tile"

import player1Image from "../../../images/player1.png"
import player2Image from "../../../images/player2.png"
import player3Image from "../../../images/player3.png"
import player4Image from "../../../images/player4.png"

let F;
let originX = 0;
let originY = 0;
let mapXs = 0;
let mapYs = 0;
let highlight; // Graphics object for highlighting
let onSelectionChange; // when a tile is selected call this function

// Define Phaser's preload function
export const preload = (scene, f) => {
  F = f;
  
  // Load images 
  scene.load.image('player1', player1Image);
  scene.load.image('player2', player2Image);
  scene.load.image('player3', player3Image);
  scene.load.image('player4', player4Image);

};

// Define Phaser's create function
export const create = (scene, osc, oX, oY) => {
  onSelectionChange = osc;
  originX = oX;
  originY = oY;
  mapXs = F.cacheSize;
  mapYs = F.playerCount;

  // Create the grid
  for (let x = 0; x < mapXs; x=x+1) {
    for (let y = 1; y <= mapYs; y=y+1) {
      if (x === 0) placeTile(scene, x, y, `player${y}`, y);
      else placeTile(scene, x, y, 'empty');
    }
  }
  
  highlight = scene.add.graphics({ lineStyle: { width: 4, color: 0xff69b4 } }); // Customize the highlight style
};


export const placeTile = (scene, x, y, type, o = 0) => {
  const key = `cache: ${x}-${y}`;
  const existingTile = F.tiles[key];
  if (existingTile) { destroyTile(scene, existingTile); }

  let zone = 'cache';
  let owner = o; 

  if (type === 'blank') return null;

  const { background, mirror, centerPoint, overlay } = buildTile(scene, F, type, key, originX+x, originY+y-1, function () {
      const tile = F.tiles[this.getData('key')];
      highlight.clear();
      highlight.strokeRect(this.x-26 , this.y-26, (F.gridSize+F.gridSpacing)+2, (F.gridSize+F.gridSpacing)+2);
/*
      if (F.isPlacing && F.selectedTile.zone === 'cache') {
        placeTile(scene, tile.x, tile.y, F.selectedTile.type, F.currentPlayer);
        placeTile(scene, F.selectedTile.x, F.selectedTile.y, 'empty');
        F.isPlacing = false;
        toggleTile(scene, 11, 9, F.isPlacing)
      }
*/
      if (onSelectionChange) onSelectionChange(tile);
  });   
  
  // store in tiles
  F.tiles[key] = {
    key: key, zone: zone, owner: owner, type: type, x: x, y: y, selected: false, 
    background: background, mirror: mirror, centerPoint: centerPoint, overlay: overlay
  }

  return F.tiles[key];
};

export const indicateTurn = (scene) => {
  // set indicator
  for(let y=1;y<=F.playerCount;y++) {
    const tile = getTile(0,y);
    if (tile) {
      if (y === F.currentPlayer) tile.overlay.setAlpha(0.0);
      else tile.overlay.setAlpha(0.75);
    }
  }  
};

export const getTile = (x,y) => {
    const key = `cache: ${x}-${y}`;
    return F.tiles[key];
};
