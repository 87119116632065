import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"
import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Typography, Grid, Button}  from '@mui/material';
import moment from 'moment';
import {getHostName, makeUrl} from '../../../api';

function MeetingDetails({meeting, onFileClick, onEditClick} ) {

  const theme = useTheme()
  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block",
    },  
    bordered: {
      border: "2px solid black"
    }
  }

  function compareDates( a, b ) {
    if ( moment(a.recStart).isBefore(moment(b.recStart)) ){
      return -1;
    }
    if ( moment(a.recStart).isAfter(moment(b.recStart)) ){
      return 1;
    }
    return 0;
  }
  
if (meeting)  return (
    <Card >
      <CardActionArea sx={{padding: '4px'}}>
        <Typography noWrap gutterBottom variant="h5" component="div" textAlign={'center'}>
          {meeting.topic}
          <Typography variant="body2" color="text.secondary">{`Account: ${getHostName(meeting.hostId)} `}
            <Button style={{fontSize: '12px', lineHeight: '100%'}} onClick={()=>onEditClick()}>{`Edit`}</Button>
          </Typography>
        </Typography>
        <CardContent sx={{ minHeight: '386px', maxHeight: '386px', overflow: 'auto', fontSize: '10px' }}>
          {meeting.files && meeting.files.sort(compareDates).map((x, lindex) => (
          <Grid container alignItems="center" justify="flex-start" direction="row" spacing={3} >
          <Grid item md={2}>
              <Button style={{fontSize: '10px', lineHeight: '80%'}} onClick={()=>onFileClick(x.fileId)}>
                {`${x.fileType}  `}                      
              </Button>
            </Grid>
            <Grid item md={3}>
            <Typography style={{fontSize: '10px', lineHeight: '80%' }} >
              {`${moment(x.recStart).format('hh:mm')} - ${moment(x.recStop).format('hh:mm')}`}                      
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography style={{fontSize: '10px', lineHeight: '80%'}} >
              {`${x.recType}  `}                      
            </Typography>
          </Grid>
        </Grid>
      ))}  
        </CardContent>
      </CardActionArea>
    </Card>  
  );
if (!meeting) return (
  <Card >
    <CardActionArea>
      <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
        No Meeting Selected
        <Typography variant="body2" color="text.secondary">{`Click on a row above to select a meeting ... `}</Typography>
      </Typography>
      <CardContent sx={{ minHeight: '336px', maxHeight: '336px', overflow: 'auto', fontSize: '10px' }}>
      </CardContent>
    </CardActionArea>
  </Card>  

);

}

export default MeetingDetails;
