export const buildTile = (scene, F, type, key, x, y, onPointerDown) => {  

    const pos = getScreenPosition(x, y, F);

    // Place a one-pixel object at the center
    const centerPoint = scene.add.rectangle(pos.x, pos.y, 1, 1, 0x0000ff); // 1x1 pixel size
    scene.physics.add.existing(centerPoint);
    centerPoint.body.setImmovable(true);
  
    const background = scene.add.image(pos.x, pos.y, 'empty').setOrigin(0.5, 0.5);
    scaleImage(F.gridSize, background);
    //background.setAlpha(0.9)
  
  
    const mirror = scene.add.image(pos.x, pos.y, type).setOrigin(0.5, 0.5);
    scaleImage(F.gridSize, mirror);
    mirror.setData('key', key);
    mirror.setInteractive();
  
    const overlay = scene.add.image(pos.x, pos.y, 'empty').setOrigin(0.5, 0.5);
    scaleImage(F.gridSize, overlay);
    overlay.setAlpha(0.0)
    overlay.disableInteractive();
  
    // Add an event listener for the click (pointer down) event
    mirror.on('pointerdown', onPointerDown);   

    return {background, centerPoint, mirror, overlay}
};

export const destroyTile = (scene, tile) => {  
    tile.background.destroy(); 
    tile.mirror.destroy(); 
    tile.centerPoint.destroy(); 
    tile.overlay.destroy(); 
};

function getScreenPosition(x,y, F) {
    return {
      x: x * (F.gridSize+F.gridSpacing) + (F.gridSize+F.gridSpacing) * 0.5 + F.gridMarginX ,
      y: y * (F.gridSize+F.gridSpacing) + (F.gridSize+F.gridSpacing) * 0.5 + F.gridMarginY
    }
  }
  
function scaleImage(size, image, ratio = 1.0) {
    image.setScale((size / image.width) * ratio, (size / image.height) * ratio);
}