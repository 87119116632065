import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Paper, Container } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import MainFrame from "../MainFrame"
import PapperBlock from '../../Forms/PapperBlock/PapperBlock';
import DynaForm from "../../Forms/DynaForm"
import {apiV2} from '../../../api';
import AddIcon from '@mui/icons-material/Add';

export default function EditInventory() {
    var { invitemId } = useParams();
    const [invitem, setinvitem] = useState(null);

    function setMessage() {}

    useEffect(() => {
        apiV2(`invitem/maint/${invitemId}`).then(res0 => {
          if (res0.error) { setMessage({variant: "error", msg: res0.error}); return null;}
          setinvitem(res0);
        });
      }, [invitemId]);
    
      const saveInvitem = (values) => {
        apiV2(`invitem/revise/${invitemId}`, 'POST', {values: values}).then(res0 => {
          if (res0.error) setMessage({variant: "error", msg: res0.error});
          else {
            setinvitem(res0.model);
            setMessage({variant: "info", msg: res0.message});
          }
        });
      };
    

    const theme = useTheme()

    const styles = {    
        header: {
            backgroundColor: '#616661',
        },
        link: {
            color: 'primary.contrastText',
            textDecoration: 'none'
        },
        customComponent: { // to be used with none MUI components
            backgroundColor: theme.palette.primary.main
        },
        fullscreen: {
            width: "100vw",
            height: "100vh",
            backgroundColor: '#e2e2e2',
            padding: '10px',
        },
        paper: {
            width: "calc(100vw - 10)",
            height: "calc(100vh - 10)",
            backgroundColor: '#e2ebe2',
            padding: '10px',
        },    
    }
    
    const icon = <AddIcon />
    if (!invitem) return null;
    return (
        <MainFrame pageTitle="View Inventory">
        <Container sx={styles.fullscreen}>
            <Paper sx={styles.paper} >    
                <PapperBlock title={`${invitem.title}`} whiteBg icon={icon} desc={`${invitem._id}`} >
                <DynaForm onSubmit={(values) => saveInvitem(values)} submitLabel="Save Invitem" fields={[     
                    {
                        name: 'title',
                        defaultValue: invitem.title ,
                        fieldType: 'text',
                        valueType: 'text',
                        placeholder: "Title",
                        label: "Title",
                        size: 8,
                        required:true
                    }
                ]}/>
                </PapperBlock>

            </Paper>
        </Container >
    </MainFrame>


    )
}