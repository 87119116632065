import { Link } from "react-router-dom";
import { Paper, Container } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import MainFrame from "../MainFrame"

export default function EditInventory() {
    const theme = useTheme()

    const styles = {    
        header: {
            backgroundColor: '#616661',
        },
        link: {
            color: 'primary.contrastText',
            textDecoration: 'none'
        },
        customComponent: { // to be used with none MUI components
            backgroundColor: theme.palette.primary.main
        },
        fullscreen: {
            width: "100vw",
            height: "100vh",
            backgroundColor: '#e2e2e2',
            padding: '10px',
        },
        paper: {
            width: "calc(100vw - 10)",
            height: "calc(100vh - 10)",
            backgroundColor: '#e2ebe2',
            padding: '10px',
        },    
    }
    
    return (
        <MainFrame pageTitle="Edit Inventory">
        <Container sx={styles.fullscreen}>
            <Paper sx={styles.paper} >    
                <div>
                    <h1>Edit! </h1>
                    <p>Here are some helpful links:</p>
                    <Link to='/'>Home</Link>
                </div>
            </Paper>
        </Container >
    </MainFrame>


    )
}