
const API = process.env.REACT_APP_API_URL;

export const apiV2 = (command, method, body, sorts, qs) => {
    const { user } = AuthenticatedUserV2();
    const myHeaders = new Headers({ Accept: 'application/json' });
    myHeaders.append('Content-Type', 'application/json');
    let finalCommand = `${API}/${command}/${user ? user._id : '0'}${buildSort(sorts)}`;
    if (qs) finalCommand += qs;
    return fetch(finalCommand, {
      method: method || 'GET',
      headers: myHeaders,
      body: body ? JSON.stringify(body) : null
    })
      .then(response => response.json().then(json => {
        if (json && json.error) {
          // toast.error(json.error);
          console.log(json.error);
        } else {
          // toast.success("Yes") //- we will display success locally
        }
        return json;
      })
        .catch(err => {
          console.log(err);
          // toast.error(err);
          return { error: err.message };
        }))
      .catch(err => {
        console.log(err);
        // toast.error(err);
        return { error: err.message };
      });
  };
  
  export const apiFDV2 = (command, method, body, sorts, headers) => {
    const { user, token } = AuthenticatedUserV2();
    var myHeaders = headers ? headers : new Headers({ Accept: "application/json", Authorization: `Bearer ${token}`});

    return fetch(`${API}/${command}/${user._id}${buildSort(sorts)}`, {
        method: method?method:'GET',
        headers: myHeaders,
        body: body
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        return {error: err.message};
    })
}
  export const AuthenticatedUserV2 = () => {
    if (typeof window !== 'undefined' && localStorage.getItem('jwt')) {
      const jwt = JSON.parse(localStorage.getItem('jwt'));
      if (jwt.user && jwt.token) { return jwt; }
    }

    return { user: null, token: null };
  };

  export const isProtected = (obj) => obj && obj.permTags && obj.permTags.includes('MEMBERSHIP');

  function buildSort(qIn) {
    if (!qIn) return '';
  
    const qSortBy = qIn.sortBy ? `&sortBy=${qIn.sortBy}` : '';
    const qOrder = qIn.order ? `&order=${qIn.order}` : '';
    const qLimit = qIn.limit ? `&limit=${qIn.limit}` : '';
    const qTag = qIn.tag ? `&tag=${qIn.tag}` : '';
    const qSourceId = qIn.sourceId ? `&sourceId=${qIn.sourceId}` : '';
    const qOut = '?' + (qSortBy + qOrder + qLimit + qTag + qSourceId).substring(1);
  
    if (qOut === '?') return '';
    return qOut;
  }
  
  export function isImage(file) {
    if (!(file instanceof Blob)) return false;
    const fileName = file.name || file.path;
    const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
    if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png') {
      return true;
    }
    return false;
  }
  
  export const appendUnique = (url) => {
    const min = 100000;
    const max = 999999;
    const rndcode = Math.floor(Math.random() * (max - min + 1) + min);

    return `${stripUnique(url)}?${rndcode}`
}


export const stripUnique = (url) => {
  var q = url.indexOf("?");
  if (q < 0) q = url.length;
  return url.substring(0,q);
}
export const storeJWT = (data, next) => {
  if (data) {
    if (typeof window !== 'undefined') {
      localStorage.setItem('jwt', JSON.stringify(data));
      next();
    }
  } else {
    localStorage.removeItem('jwt');
    next();
  }
};


export const storeJWTUser = (user, next) => {
  if (user) {
    if (typeof window !== 'undefined') {
      const data = AuthenticatedUserV2();
      data.user = user;
      localStorage.setItem('jwt', JSON.stringify(data));
      next();
    }
  }
};


export const userPref = (name) => {
  const jwt = AuthenticatedUserV2();
  if (jwt && jwt.user && jwt.user.preferences) return jwt.user.preferences[name];
  return null;
};

export const isClientAuthenticated = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return (localStorage.getItem('jwt'));
};

// export const isAuthenticated = () => { return callAPI(`isAuth`) };
export const isLoggedIn = () => {
  if (typeof window !== 'undefined' && localStorage.getItem('jwt')) {
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    if (jwt.user) {
      if (!jwt.user) return false;
      if (!jwt.user.permissions) return false;
      return jwt.user.email !== 'a@b.com';
    }
  }
  return false;
};

export const isAdmin = () => {
  if (typeof window !== 'undefined' && localStorage.getItem('jwt')) {
    const jwt = JSON.parse(localStorage.getItem('jwt'));
    if (jwt.user) {
      if (!jwt.user) return false;
      if (!jwt.user.permissions) return false;
      return jwt.user.permissions.includes('ADMIN');
    }
  }
  return false;
};

export const isMember = () => {
  const jwt = AuthenticatedUserV2();
  return jwt.user.isMember;
};

export const refreshUser = (olduser) => {
  const jwt = AuthenticatedUserV2();
  const { user, token } = AuthenticatedUserV2();

  const myHeaders = new Headers({ Accept: 'application/json', Authorization: `Bearer ${token}` });
  myHeaders.append('Content-Type', 'application/json');

  return fetch(`${API}/user/refresh/${olduser._id}/${user._id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      jwt.user = response;
      storeJWT(jwt, () => response.json());
    })
    .catch(err => {
      console.log(err);
    });
};

export const getHostName = (hostId) => {
  switch (hostId) {
    case '8TmdQRm5R_CfdksQHFGZ9w' : return "Heather"; break;
    case 'zI3Kke8IQdWYj0jcNmji8A' : return "Jim"; break;
    case 'ZDu4rriKTre8emA0fH4yPQ' : return "Lasting Impact"; break;
    default: return "Unknown"; break;
  }
}

export const  makeUrl = (file) => {
  if (!file) return;
  return `https://licontent.nyc3.cdn.digitaloceanspaces.com/recordings/${file.fileId}.${file.fileExt}`
}
