import React, { useState, useRef, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';

import { Container } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import {apiV2 } from '../../../api';

import ClickCard from "../../Cards/ClickCard/ClickCard";
import cardImage from "../../../images/contemplative-reptile.jpg"
import MainFrame from "../MainFrame"

import "./styles.css";

function App(props) {

  const scrollRef = useRef(null);
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();

  const [modules, setmodules] = useState([]);

  const theme = useTheme()

  const handleOnClick = (url) => navigate(url);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    apiV2(`modules`, 'POST', null,{sortBy: 'sequence', order: 'Asc'}).then(res0 => {
      if (!res0.error) setmodules(res0);
    });
   
  }, []);


  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
  }

  return (
    <MainFrame pageTitle="Glance Theater Manager">

      <Container sx={styles.fullscreen}>
        <div ref={scrollRef} ></div>

        {modules && modules.map((x, lindex) => (
            <ClickCard 
              image={cardImage} 
              handleOnClick={()=>handleOnClick(`${x.url}`)}
              title={x.title}
              desc={x.description}
            />
        ))}         

        </Container>
      </MainFrame>
  );
}
export default App;
