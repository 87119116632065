import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles"

import { Card, CardContent, CardMedia, CardActionArea}  from '@mui/material';
import { Button}  from '@mui/material';
import DynaForm from "../../Forms/DynaForm";
import moment from "moment";


import {apiV2 } from '../../../api';


function Prompter() {
    const [result, setresult] = useState('');
    const [tone, settone] = useState('');
    const [description, setdescription] = useState('');
    const [outline, setoutline] = useState(false);

  const getContent = (values) => {   
    apiV2(`blogger/prompt`, 'POST', {values: values}).then(res0 => {
      if (res0.error) {console.log(res0.error)} //setMessage({variant: "error", msg: res0.error});
      else {
        //setMessage({variant: "info", msg: "Esca event Saved"});
        console.log(res0.postContent)
        setresult(res0.postContent.replace(/\n/g, '<br />'));
      }
    });
  };

  return (
    <Card  style={{ maxHeight: '800px', minHeight: '800px', overflow: 'auto' }}>
        <CardContent >
            <DynaForm
              onSubmit={(values) => getContent(values)}
              submitLabel="Save"
              fields={[
                {
                    name: 'tone',
                    defaultValue: tone ,
                    fieldType: 'select',
                    valueType: 'text',
                    placeholder: "Tone",
                    label: "Tone",
                    size: 6,
                    required:true,
                    options: [ {id: "friendly", label: "Friendly"}, {id: "professional", label: "Professional"}, {id: "humorous", label: "Humorous"} ]
                  },
                  {
                  name: 'outline',
                  defaultValue: outline,
                  fieldType: 'checkbox',
                  valueType: 'boolean',
                  placeholder: "Outline",
                  label: "Outline",
                  size: 6,
                  required:true
                }  ,
                  {
                  name: 'description',
                  defaultValue: description,
                  fieldType: 'text',
                  valueType: 'text',
                  placeholder: "Description",
                  label: "Description",
                  size: 12,
                  required:true
                }                             
              ]}
            />    
            <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignContent: 'center', gap: '2rem', overflow: 'auto'}}
                dangerouslySetInnerHTML={{ __html: result }}
            />
           
        </CardContent>
    </Card>          
  );
}

export default Prompter;
/*
 topic: String,
        allowSee: [String],
        allowDo: [String],
        shouldRemove: Boolean,

*/