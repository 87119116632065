import React, { useState, useRef, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';

import { Container, Button, Grid } from "@mui/material"

import { useTheme } from "@mui/material/styles"
import {apiV2 } from '../../../api';

import MeetingCard from "../../Cards/MeetingCard/MeetingCard";
import cardImage from "../../../images/contemplative-reptile.jpg"
import MainFrame from "../MainFrame"
import Login from "../User/Login";
import TextFileViewer from "./TextFileViewer";
import { Mail as MailIcon  } from '@mui/icons-material';

import "./styles.css";
import { AlternateEmail } from "@mui/icons-material";
import BasiceDataGrid from "../../Grids/BasicDataGrid";
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import MeetingDetails from "./MeetingDetails"
import MeetingVideoViewer from "./MeetingVideoViewer"
import BlankFileViewer from "./BlankFileViewer"
import MeetingEditor from "./MeetingEditor"

import moment from 'moment'

window.moment = moment;

function MeetingStudio() {

  const scrollRef = useRef(null);
  const dataFetchedRef = useRef(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const [meetings, setmeetings] = useState([]);
  const [meeting, setmeeting] = useState(null);
  const [file, setfile] = useState(null);
  const [isPlaying, setisPlaying] = useState(false);

  const theme = useTheme()
  const handleOnClick = (url) => navigate(url);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    apiV2(`meetings`, 'POST', {filter: {isMoved: true}},{sortBy: 'startDate', order: 'Asc'}).then(res0 => {
      if (!res0.error) setmeetings(res0);
    });
   
  }, []);


  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block"
    },  
    bordered: {
      border: "2px solid black"
   }
}
  
  const columns = [
    { name: '_id', header: 'Id', minWidth: 40, defaultVisible: false, defaultFlex: 2 },
    { name: 'topic', header: 'Topic', maxWidth: 1000, defaultFlex: 1 },
    {
      name: 'startDate',
      header: 'Date',
      defaultFlex: 1,
      minWidth: 200,
      render: ({ value, cellProps }) => {
        return moment(value).format('YYYY-MM-DD  HH:mm')
      }
    },
    { name: 'duration', header: 'Length', type: 'number', minWidth: 40, maxWidth: 100, defaultFlex: 1 },
    { name: 'fileCount', header: 'Files', type: 'number', minWidth: 40, maxWidth: 76, defaultFlex: 1 },
    { name: 'fileSize', header: 'Size', type: 'number', minWidth: 40, maxWidth: 1000, defaultFlex: 1 },
  ];
  
  const filters = [
    { name: 'topic', operator: 'contains', type: 'string', value: '' },
    { name: 'startDate', operator: 'contains', type: 'string', value: ''},
  ]

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setisPlaying(!isPlaying);
    }
  };


  const onRowSelected = (meeting) => {
    setmeeting(meeting);
    if (!file || file.fileType !== 'EDIT') setfile(null);
  };    

  const onFileClick = (fileId) => {
    if (meeting && fileId) {
      console.log(fileId)
      setfile(meeting.files.find(x=>x.fileId === fileId ));
    }
  };  

    const onEditClick = () => {
      if (meeting) {
        setfile({fileType: 'EDIT'});
      }
    };  
    
    const onMeetingChanged = (newMeeting) => {
      if (newMeeting) {
        console.log(newMeeting)
        setmeeting(newMeeting);
        const updatedList = meetings.map(item => 
          item._id === newMeeting._id ? newMeeting : item
        );
        setmeetings(updatedList);
      }
    };  

    const putDisplay = () => {
      if (meeting) {
        if (file) {
          switch(file.fileType) {
            case "EDIT": return <MeetingEditor meeting={meeting} onMeetingChanged={onMeetingChanged}/>; break;
            case "CHAT": return <TextFileViewer meeting={meeting} file={file} />; break;
            case "MP4": return <MeetingVideoViewer meeting={meeting} file={file}/>; break;
            case "M4A": return <MeetingVideoViewer meeting={meeting} file={file}/>; break;
          }
        }
      } 
      return <BlankFileViewer />
    };

    const menu = [
      {title: "List Meetings", icon: <MailIcon />, handler: ()=>navigate("/meetingmanager")},
      {title: "Move Meetings", icon: <MailIcon />, handler: ()=>navigate("/meetingmover")}
    ];
    return (
      <MainFrame pageTitle="Glance Theater Manager" sideMenu={menu}>
        <Container sx={styles.fullscreen}>
          <Grid container justify="flex-start" direction="row" spacing={1} >
          <Grid item md={12}>
              <BasiceDataGrid columns={columns} filters={filters} data={[...meetings]} onRowSelected={onRowSelected} height={'426px'}></BasiceDataGrid>       
            </Grid>
            <Grid item md={5}>
              <MeetingDetails meeting={meeting} onFileClick={onFileClick} onEditClick={onEditClick}/>
            </Grid>
           <Grid item md={7}  >
            {putDisplay()}
            </Grid>
          </Grid>
        </Container>
    </MainFrame>
  );
}
export default MeetingStudio;
  