import React, { useState, useRef, useEffect } from "react";
import {useNavigate, Link} from 'react-router-dom';
import { useAlert } from '../../AlertContext';

import { Container, Button, Grid } from "@mui/material"

import { useTheme } from "@mui/material/styles"
import {apiV2 } from '../../../api';

import MainFrame from "../MainFrame"
import { Mail as MailIcon  } from '@mui/icons-material';
import MeetingSelector from "./MeetingSelector"
import BasiceDataGrid from "../../Grids/BasicDataGrid";

import "./styles.css";

import moment from 'moment'

window.moment = moment;

function MeetingManager() {
  const { notify } = useAlert();

  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [filter, setfilter] = useState({ 
    account: null, topic: null, 
    year: moment().year(), 
    month: moment().month()+1
   });
  const [meetings, setmeetings] = useState(null);
  
  const handleOnClick = (url) => navigate(url);

  const theme = useTheme()
  const styles = {    
    fullscreen: {
        width: "100vw",
        height: "100vh",
        backgroundColor: '#e2e2e2',
        padding: '10px',
    },
    paper: {
      width: "100%",
      height: "100%",
      display: "inline-block"
    },  
    bordered: {
      border: "2px solid black"
   }
  }
  

  const menu = [
    {title: "Move Meetings", icon: <MailIcon />, handler: ()=>navigate("/meetingmover")}
  ];

  const processFilter = (values) => {   
    setfilter(values)
    values.from = `${values.year}-${values.month}-01`;
    values.to = `${values.year}-${values.month}-31`;
    apiV2(`listRecordings`, 'POST', {values: values}).then(res0 => {
      if (res0.error) {console.log(res0.error)} //setMessage({variant: "error", msg: res0.error});
      else {
        //setMessage({variant: "info", msg: "Esca event Saved"});
        console.log("response:")
        console.log(res0)
        setmeetings(res0.meetings);
        res0.meetings.length === 0 ? notify('warning', 'No records found ...') : notify('info', `${res0.meetings.length} records found`);

      }
    });
    
  };

  const onRowSelected = (meeting) => {
    //setmeeting(meeting);
    //if (!file || file.fileType !== 'EDIT') setfile(null);
  };    

  const columns = [
    { name: '_id', header: 'Id', minWidth: 40, defaultVisible: false, defaultFlex: 2 },
    { name: 'topic', header: 'Topic', maxWidth: 1000, defaultFlex: 1 },
    {
      name: 'start_time',
      header: 'Date',
      defaultFlex: 1,
      minWidth: 200,
/*
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: 'MM-DD-YYYY',
          cancelButton: false,
          highlightWeekends: false,
        }
      },
*/
      render: ({ value, cellProps }) => {
        return moment(value).format('YYYY-MM-DD  hh:mm')
      }
    },
    { name: 'duration', header: 'Length', type: 'number', minWidth: 40, maxWidth: 100, defaultFlex: 1 },
    { name: 'recording_count', header: 'Files', type: 'number', minWidth: 40, maxWidth: 76, defaultFlex: 1 },
    { name: 'total_size', header: 'Size', type: 'number', minWidth: 40, maxWidth: 1000, defaultFlex: 1 },
  ];
  
  const filters = [
    { name: 'topic', operator: 'contains', type: 'string', value: '' },
    { name: 'startDate', operator: 'contains', type: 'string', value: ''},
  ]


  return (
    <MainFrame pageTitle="Glance Theater Manager" sideMenu={menu}>
      <Container sx={styles.fullscreen}>
        <Grid container justify="flex-start" direction="row" spacing={1} >
          <Grid item md={5}>
            <MeetingSelector filter={filter} onFilterChanged={processFilter}/>
          </Grid>
          <Grid item md={7}  >
            {filter && <>
              <p>{`Account: ${filter.account}`}</p>
              <p>{`Date: ${filter.year}-${filter.month}-01 - ${filter.year}-${filter.month}-31`}</p>
            </>}
          </Grid>
          <Grid item md={12}>
            <BasiceDataGrid columns={columns} filters={filters} data={meetings ? [...meetings] : []} onRowSelected={onRowSelected} height={'540px'}></BasiceDataGrid>       
          </Grid>
        </Grid>
      </Container>
    </MainFrame>
  );
}
export default MeetingManager;
  